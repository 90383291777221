import React from 'react'
import { Link } from 'gatsby'
import {
  Heading,
  Section,
  FilledButton,
  GridWrapper,
  GridItem,
} from '@jsluna/react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Hero from '../components/Hero'
import routes from '../config/routes'
import './index.scss'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section className="page">
      <Hero />
      <GridWrapper matrix className="mt2">
        <GridItem size="1" className="activity_wrapper">
          <GridWrapper
            matrix
            equalHeight
            verticalAlign="center"
            horizontalAlign="center"
          >
            <Heading level={3} className="mt1">
              Sorry! That page doesn’t seem to exist.
            </Heading>
            <Link to={routes.home} className="info_graphic_cta">
              <FilledButton type="button" className="info_graphic_cta">
                Go to home
              </FilledButton>
            </Link>
          </GridWrapper>
        </GridItem>
      </GridWrapper>
    </Section>
  </Layout>
)

export default NotFoundPage
